import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import contactImage from "/static/assets/images/contact-dec.png"
import Obfuscate from "react-obfuscate"
// import { useStaticQuery, graphql } from "gatsby"
import { useNavMenu } from "../hooks/useNav"
import Form from "./Form"
export default function Contact() {
  const contactRef = useNavMenu("contact")

  // const {
  //   site: {
  //     siteMetadata: { position },
  //   },
  // } = useStaticQuery(graphql`
  //   query {
  //     site {
  //       siteMetadata {
  //         position
  //       }
  //     }
  //   }
  // `)
  return (
    <>
      <div id="contact" ref={contactRef} className="contact-us section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="section-heading">
                <h6>Contact Us</h6>
                <h4>
                  Get In Touch With Us <em>Now</em>
                </h4>
                <div className="line-dec" />
              </div>
            </div>
            <div className="col-lg-12" id="contact-details">
              <div className="row">
                <div className="col-lg-12">
                  <div className="contact-dec">
                    {/* <StaticImage
                        src="../../static/assets/images/contact-dec.png"
                        alt="Contact"
                      /> */}
                    <img src={contactImage} alt="contact" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div id="map">
                    <iframe
                      id="contact-map"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.2333901936863!2d-0.09322928471595185!3d51.52727897963839!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc239f712c71c1937!2zNTHCsDMxJzM4LjIiTiAwwrAwNScyNy44Ilc!5e0!3m2!1sen!2suk!4v1644809004375!5m2!1sen!2suk"
                      width="100%"
                      height="636px"
                      frameBorder={0}
                      style={{ border: 0 }}
                      allowFullScreen
                    />
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="fill-form">
                    <div className="row">
                      {/* <div class="col-lg-4">
                <div class="info-post">
                  <div class="icon">
                    <img src="/assets/images/phone-icon.png" alt="">
                    <a href="#"></a>
                  </div>
                </div>
              </div> */}
                      <div className="col-lg-6">
                        <div className="info-post">
                          <div className="icon">
                            <StaticImage
                              src="../../static/assets/images/email-icon.png"
                              alt="E-Mail"
                              placeholder="blurred"
                            />
                            {/* <img src="/assets/images/email-icon.png" alt="" /> */}
                            <Obfuscate email="info@akmsoftware.co.uk" />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="info-post">
                          <div className="icon">
                            <StaticImage
                              src="../../static/assets/images/location-icon.png"
                              alt="Location"
                              placeholder="blurred"
                            />
                            {/* <img
                                src="/assets/images/location-icon.png"
                                alt=""
                              /> */}
                            <a href="#">
                              124 City Road, London,
                              <br /> EC1V 2NX / United Kingdom
                            </a>
                          </div>
                        </div>
                      </div>

                      <Form />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
