import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
export default function Form() {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })
  const onSubmit = data => {
    console.log(data)
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }
    fetch("/api/contact", requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data)
      })
  }

  return (
    <form method="post" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-lg-6">
          <fieldset>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Name"
              autoComplete="on"
              // required
              {...register("name", { required: true, maxLength: 80 })}
              className={`form-control ${errors.name ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.name?.message}</div>
          </fieldset>
          <fieldset>
            <input
              type="text"
              name="email"
              id="email"
              // pattern="[^ @]*@[^ @]*"
              placeholder="Your Email"
              // required
              {...register("email", {
                required: true,
                pattern: /[^ @]*@[^ @]*/i,
              })}
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.email?.message}</div>
          </fieldset>
          <fieldset>
            <input
              type="text"
              name="subject"
              id="subject"
              placeholder="Subject"
              autoComplete="on"
              {...register("subject", { required: true })}
              className={`form-control ${errors.subject ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.subject?.message}</div>
          </fieldset>
        </div>
        <div className="col-lg-6">
          <fieldset>
            <textarea
              name="message"
              className="form-control"
              id="message"
              placeholder="Message"
              //   required
              //   defaultValue={""}
              {...register("message", {
                required: true,
              })}
              className={`form-control ${errors.message ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.message?.message}</div>
          </fieldset>
        </div>
        <div className="col-lg-12">
          <fieldset>
            <button type="submit" id="form-submit" className="main-button">
              Send Message Now
            </button>
          </fieldset>
        </div>
      </div>
    </form>
  )
}
