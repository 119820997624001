import React from "react"
import Layout from "../components/Layout"
import Slider from "../components/Slider"
import About from "../components/About"
import Services from "../components/Services"
import Contact from "../components/Contact"
export default function Home() {
  return (
    <>
      <Layout>
        <Slider></Slider>
        <About></About>

        <Services></Services>
        <Contact></Contact>
      </Layout>
    </>
  )
}
