import React from "react"
import ReactPlayer from "react-player/wistia"

export default function Video() {
  return (
    <>
      <div
        className="wistia_responsive_padding"
        style={{ padding: "56.25% 0 0 0", position: "relative" }}
      >
        <div
          className="wistia_responsive_wrapper"
          style={{
            height: "100%",
            left: 0,
            position: "absolute",
            top: 0,
            width: "100%",
          }}
        >
          <div
            className="wistia_embed wistia_async_j21chja89q videoFoam=true"
            style={{
              height: "100%",
              position: "relative",
              width: "100%",
            }}
          >
            <div
              className="wistia_swatch"
              style={{
                height: "100%",
                left: 0,
                opacity: 0,
                overflow: "hidden",
                position: "absolute",
                top: 0,
                transition: "opacity 200ms",
                width: "100%",
              }}
            >
              <ReactPlayer
                url="https://kingemkurdam.wistia.com/medias/j21chja89q"
                config={{
                  wistia: {
                    videoFoam: true,
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
