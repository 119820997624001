import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useNavMenu } from "../hooks/useNav"

export default function Services() {
  const tabs = ["consultancy", "coding", "delivery"]
  const [active, setActive] = useState(tabs[0])
  const servicesRef = useNavMenu("services")

  return (
    <>
      <div id="services" ref={servicesRef} className="services section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading">
                <h6>Our Services</h6>
                <h4>
                  What Our Company <em>Provides</em>
                </h4>
                <div className="line-dec" />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="naccs">
                <div className="grid">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="menu">
                        <div
                          className={`first-thumb ${
                            active === "consultancy" ? "active" : ""
                          }`}
                        >
                          <div
                            className="thumb"
                            onClick={() => {
                              setActive("consultancy")
                            }}
                          >
                            <span className="icon">
                              {/* <img
                                src="/assets/images/service-icon-03.png"
                                alt=""
                              /> */}
                              <StaticImage
                                src="../../static/assets/images/service-icon-03.png"
                                placeholder="blurred"
                                alt="consultancy"
                              />
                            </span>
                            Consultancy
                          </div>
                        </div>
                        <div
                          className={`first-thumb ${
                            active === "coding" ? "active" : ""
                          }`}
                        >
                          <div
                            className="thumb"
                            onClick={() => {
                              setActive("coding")
                            }}
                          >
                            <span className="icon">
                              {/* <img
                                src="/assets/images/service-icon-01.png"
                                alt=""
                              /> */}
                              <StaticImage
                                src="../../static/assets/images/service-icon-01.png"
                                placeholder="blurred"
                                alt="coding"
                              />
                            </span>
                            Coding
                          </div>
                        </div>
                        <div
                          className={`first-thumb ${
                            active === "delivery" ? "active" : ""
                          }`}
                        >
                          <div
                            className="thumb"
                            onClick={() => {
                              setActive("delivery")
                            }}
                          >
                            <span className="icon">
                              {/* <img
                                src="/assets/images/service-icon-04.png"
                                alt=""
                              /> */}
                              <StaticImage
                                src="../../static/assets/images/service-icon-04.png"
                                placeholder="blurred"
                                alt="delivery"
                              />
                            </span>
                            Delivery
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <ul className="nacc">
                        <li
                          className={`${
                            active === "consultancy" ? "active" : ""
                          }`}
                        >
                          <div>
                            <div className="thumb">
                              <div className="row">
                                <div className="col-lg-6 align-self-center">
                                  <div className="left-text">
                                    <h4>Consultancy</h4>
                                    <p>
                                      We help businesses accelerate their
                                      software ideas.
                                    </p>
                                    <div className="ticks-list">
                                      <span>
                                        ✔️ Choosing the right technology.
                                      </span>
                                      <span>
                                        ✔️ Speeding up product development.
                                      </span>
                                      <span>
                                        ✔️ Reducing product development costs.
                                      </span>
                                      <span>
                                        ✔️ Helping them build sustainability
                                        software.
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 align-self-center">
                                  <div className="right-image">
                                    {/* <img
                                      src="/assets/images/services-image-03.jpg"
                                      alt=""
                                    /> */}
                                    <StaticImage
                                      src="../../static/assets/images/services-image-03.jpg"
                                      placeholder="blurred"
                                      alt="consultancy"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li
                          className={`${active === "coding" ? "active" : ""}`}
                        >
                          <div>
                            <div className="thumb">
                              <div className="row">
                                <div className="col-lg-6 align-self-center">
                                  <div className="left-text">
                                    <h4>Coding</h4>
                                    <p>Some of the technologies we use.</p>
                                    <div className="ticks-list">
                                      <span>✔️ Go</span>
                                      <span>✔️ Javascript</span>
                                      <span>✔️ PHP</span>
                                      <span>✔️ Scala</span>
                                      <span>✔️ Python</span>
                                      <span>✔️ Java</span>
                                      <span>✔️ Node.js</span>
                                      <span>✔️ TypeScript</span>
                                      <span>✔️ React.js</span>
                                      <span>✔️ Next.js</span>
                                      <span>✔️ Vue.js</span>
                                      <span>✔️ GraphQL</span>
                                      <span>✔️ GraphQL</span>
                                      <span>✔️ Swagger</span>
                                    </div>
                                    <p />
                                  </div>
                                </div>
                                <div className="col-lg-6 align-self-center">
                                  <div className="right-image">
                                    {/* <img
                                      src="/assets/images/services-image-03.jpg"
                                      alt=""
                                    /> */}
                                    <StaticImage
                                      src="../../static/assets/images/services-image-02.jpg"
                                      placeholder="blurred"
                                      alt="coding"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li
                          className={`${active === "delivery" ? "active" : ""}`}
                        >
                          <div>
                            <div className="thumb">
                              <div className="row">
                                <div className="col-lg-6 align-self-center">
                                  <div className="left-text">
                                    <h4>Delivery</h4>
                                    <p>
                                      After following the SOLID software
                                      principles. We ensure that the development
                                      process continues with CI/CD.
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 align-self-center">
                                  <div className="right-image">
                                    {/* <img
                                      src="/assets/images/services-image-04.jpg"
                                      alt=""
                                    /> */}
                                    <StaticImage
                                      src="../../static/assets/images/services-image-04.jpg"
                                      placeholder="blurred"
                                      alt="delivery"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
