import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useNavMenu } from "../hooks/useNav"

export default function About() {
  const aboutRef = useNavMenu("about")

  return (
    <>
      <div id="about" ref={aboutRef} className="about section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6">
                  <div className="about-left-image">
                    <StaticImage
                      src="../../static/assets/images/about-dec.png"
                      alt="About"
                      placeholder="blurred"
                    />
                    {/* <img src="/assets/images/about-dec.png" alt="" /> */}
                  </div>
                </div>
                <div
                  className="col-lg-6 align-self-center"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <div className="about-right-content">
                    <div className="section-heading">
                      <h6>About Us</h6>
                      <h4>
                        Who is AKM <em>Software</em>
                      </h4>
                      <div className="line-dec" />
                    </div>
                    <p>
                      AKM Software is a creative software company that
                      specializes in strategy, creativity, coding and innovative
                      technology.
                    </p>
                    <div className="row">
                      <div className="col-lg-4 col-sm-4">
                        <div className="skill-item first-skill-item">
                          <div className="progress" data-percentage={100}>
                            <span className="progress-left">
                              <span className="progress-bar" />
                            </span>
                            <span className="progress-right">
                              <span className="progress-bar" />
                            </span>
                            <div className="progress-value">
                              <div>
                                100%
                                <br />
                                <span>Consultancy</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-4">
                        <div className="skill-item second-skill-item">
                          <div className="progress" data-percentage={100}>
                            <span className="progress-left">
                              <span className="progress-bar" />
                            </span>
                            <span className="progress-right">
                              <span className="progress-bar" />
                            </span>
                            <div className="progress-value">
                              <div>
                                100%
                                <br />
                                <span>Coding</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-4">
                        <div className="skill-item third-skill-item">
                          <div className="progress" data-percentage={100}>
                            <span className="progress-left">
                              <span className="progress-bar" />
                            </span>
                            <span className="progress-right">
                              <span className="progress-bar" />
                            </span>
                            <div className="progress-value">
                              <div>
                                100%
                                <br />
                                <span>Delivery</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
