import React from "react"
import Video from "./Video"
export default function Slider() {
  return (
    <>
      <div className="main-banner" id="top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6 align-self-center">
                  <div className="left-content show-up header-text">
                    <div className="row">
                      <div className="col-lg-12">
                        <h6>AKM Software</h6>
                        <h2>We are Digital Developers</h2>
                        <p>
                          We develop digital strategies, products and service.
                        </p>
                      </div>
                      <div className="col-lg-12">
                        <div className="border-first-button scroll-to-section">
                          <a href="#contact">Free Quote</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <div className="right-image">
                    <Video></Video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
